import { MySizes } from '@interfaces/models/mySizes';
import { parseSearchPreferences } from '@helpers/utils/catalog/catalog-url-helpers';
import { useContextSelector } from 'use-context-selector';
import { UserContext } from '@context/user.context';
import React, { SetStateAction, useEffect, useState } from 'react';
import { MySizesState } from '@context/catalog.context';
import { useRouter } from 'next/router';
import { ElasticSearch } from '@interfaces/models/elasticSearch';
import { CampaignContext } from '@context/campaign.context';

export const getMySizesState = (
  applicableSizeIds: number[],
  userSizes: MySizes,
  isMySizesNotApplicable: boolean,
  isMySizesDisabled = false,
): MySizesState => {
  if (!userSizes) {
    return { enabled: false, applicable: false, preferences: null };
  }
  const stringifiedApplicableSizeIds = applicableSizeIds.map((e: number) => e.toString());
  const state = isMySizesNotApplicable
    ? false
    : stringifiedApplicableSizeIds.some((sizeId: string) => userSizes.universe.ids.includes(sizeId));
  return {
    enabled: isMySizesDisabled ? false : state,
    applicable: state,
    preferences: parseSearchPreferences(userSizes),
  };
};

const filtersHaveNoCategories = (filters: ElasticSearch['filters']) => {
  const noCategoryLvl0 = !filters['categoryLvl0.id']?.length;
  const noCategoryLvl1 = !filters['categoryLvl1.id']?.length;
  const noCategoryLvl2 = !filters['categoryLvl2.id']?.length;
  return noCategoryLvl0 && noCategoryLvl1 && noCategoryLvl2;
};

const useMySizesState = (
  applicableSizeIds: number[],
  curUrlRef: React.MutableRefObject<string>,
  filters: ElasticSearch['filters'],
  setFilters: React.Dispatch<React.SetStateAction<ElasticSearch['filters']>>,
) => {
  const userSizes = useContextSelector(UserContext, (v) => v.userSizes);
  const router = useRouter();
  const campaign = useContextSelector(CampaignContext, (v) => v.campaign);

  const { personalized, mySizesDisabled } = router?.query || {};
  const isMySizesNotApplicable = personalized === '0';
  // we use mySizesDisabled query param to keep track of mysizes disabled state when user clicks on PDP and then goes back
  const mySizesDisabledFromUrl = mySizesDisabled === '1';
  // by default, mysizes should be disabled on campaign pages, except if personalized=1 param is present in url
  const mySizesDisabledFromCampaign = !!campaign && personalized !== '1';
  const isMySizesDisabled = mySizesDisabledFromUrl || mySizesDisabledFromCampaign;

  const [mySizesState, setMySizesState] = useState<MySizesState>(() =>
    getMySizesState(applicableSizeIds, userSizes, isMySizesNotApplicable, isMySizesDisabled),
  );

  useEffect(() => {
    setMySizesState(getMySizesState(applicableSizeIds, userSizes, isMySizesNotApplicable, isMySizesDisabled));
  }, [applicableSizeIds, userSizes]);

  // this method is responsible for updating query params when there is a manual change in mySizes
  // this ensures that mysizes state is persisted when user navigates to PDP and then clicks back
  const handleMySizesUrlChange = () => {
    const { pathname, search, hash, origin } = window.location;
    const queryParams = new URLSearchParams(search);
    let hasQueryParamsChanged = false;

    // on campaign pages, when user enables mysizes, we need to set personalized=1,
    // since by default mysizes is off on campaigns
    if (!!campaign && !mySizesState.enabled) {
      queryParams.set('personalized', '1');
      hasQueryParamsChanged = true;
    }

    // handling mySizesDisabled query param here
    if (mySizesState.enabled || queryParams.get('mySizesDisabled') === '1') {
      if (mySizesState.enabled) {
        queryParams.set('mySizesDisabled', '1');
      } else {
        queryParams.delete('mySizesDisabled');
      }
      hasQueryParamsChanged = true;
    }

    if (!hasQueryParamsChanged) {
      return;
    }

    const queryString = queryParams.toString();
    const newUrl = `${origin}${pathname}${queryString ? `?${queryParams}` : ''}${hash}`;
    curUrlRef.current = newUrl;
    window.history.replaceState(null, undefined, newUrl);
  };

  const setMySizesStateWithUrl = (newState: SetStateAction<MySizesState>, manualSwitch = false) => {
    if (manualSwitch) {
      handleMySizesUrlChange();

      const isOnMultiUniversePage = applicableSizeIds?.length > 1;
      // clearing universe filter if it's the only one selected on multi-universe pages (DSXP-920)
      if (mySizesState.enabled && isOnMultiUniversePage && filters) {
        if (filters['universe.id']?.length === 1 && filtersHaveNoCategories(filters)) {
          setFilters((prev) => {
            const { 'universe.id': universeId, ...rest } = prev;
            return rest;
          });
        }
      }
    }
    setMySizesState(newState);
  };

  return { setMySizesState: setMySizesStateWithUrl, mySizesState };
};

export default useMySizesState;
