import React, { useCallback } from 'react';
import clsx from 'clsx';
import useOnReveal from '@hooks/use-on-reveal';
import CTAItem from '@components/molecules/description/components/cta-item';
import CMSImages from '@components/atoms/vc-images/components/cms-image';
import sanitizer from '@helpers/sanitizer';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import styles from './description.module.scss';

type ImageProps = {
  filename: string;
  url: string;
  title: string;
  fileSize?: string;
  contentType?: string;
};

export type GroupProps = {
  ctaText: string;
  _metadata: { uid: string };
  url: string;
  target?: string;
};

type DescriptionProps = {
  title: string;
  multi_line?: string;
  multiLine?: string;
  group: GroupProps[];
  image: ImageProps;
  ctaTextColor?: string;
  ctaBackgroundColor?: string;
  contentTypeUid: string;
  uid: string;
  displayTitle: string;
  category?: { category?: string };
  sectionIdx?: string;
};

const Description: React.FC<DescriptionProps> = ({
  title: label,
  image,
  multi_line: textArea,
  multiLine: textZone,
  group,
  ctaTextColor,
  ctaBackgroundColor: ctaBgColor,
  contentTypeUid: contentTypeUID,
  displayTitle: title,
  category,
  sectionIdx,
  uid: descriptionBlockId,
}): React.JSX.Element => {
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  const { filename: imgXName, url: imgPath, title: imgTitle } = image || {};
  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        category: 'cms_content_view',
        action: contentTypeUID,
        property: `${descriptionBlockId}_${label}`,
        label: category?.category,
        value: String(sectionIdx || ''),
      });
    }, [contentTypeUID, label, category?.category, sectionIdx, descriptionBlockId]),
    0.5,
    false,
  );
  const textLines = textArea ?? textZone;

  const ctaGroup = group?.map((item, index) => {
    const { uid = '' } = item?._metadata || {};
    const elemKey = `cta-${uid}-${index}`;

    return (
      <CTAItem
        key={elemKey}
        {...{
          item,
          index,
          ctaTextColor,
          ctaBgColor,
          label,
          contentTypeUID,
          category,
        }}
      />
    );
  });

  return (
    <section
      ref={containerRef}
      className={clsx(styles.section, styles.channel)}
    >
      <div className={clsx(styles.description__container, 'innerContainer')}>
        {imgPath ? (
          <div className={styles.description__image}>
            <CMSImages
              sizes="
                (min-width: 1024px) 50vw,
                100vw
              "
              src={imgPath}
              alt={imgTitle}
              data-src={imgXName}
              width={375}
              height={202}
            />
          </div>
        ) : null}

        <div className={styles.description__text}>
          <h1>{title}</h1>

          {textLines && (
            <div
              className={styles.description__description}
              dangerouslySetInnerHTML={{
                __html: sanitizer(textLines, {}, true),
              }}
            />
          )}

          <div className={styles['cta-container']}>{group?.length ? ctaGroup : null}</div>
        </div>
      </div>
    </section>
  );
};

export default Description;
