import CatalogQueryIndex from '@enums/catalog-query-index';
import { Preferences } from '@interfaces/models/preferences';
import { ElasticSearch } from '@interfaces/models/elasticSearch';

const getCatalogQueryIndex = (isoCode: Preferences['country'], sortBy: ElasticSearch['sortBy']): CatalogQueryIndex => {
  if (sortBy === 'relevance' && isoCode === 'US') {
    return CatalogQueryIndex.US;
  }
  switch (sortBy) {
    case 'price-asc':
      return CatalogQueryIndex.PriceAscending;
    case 'price-desc':
      return CatalogQueryIndex.PriceDescending;
    case 'recency':
      return CatalogQueryIndex.Recent;
    default:
      return CatalogQueryIndex.Default;
  }
};

export default getCatalogQueryIndex;
