import { ProductCardProps } from '@components/organisms/catalog/components/product-card/product-card';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import 'swiper/css';
import { SwiperProps } from 'swiper/react';
import { Product } from '@interfaces/models/product';
import ProductSlider from '@components/organisms/product-block/product-slider/product-slider';
import useWindowSize from '@hooks/use-window-size';
import Link from '@components/atoms/link/link';
import styles from './product-block.module.scss';

export interface ProductBlockProps {
  products: Product[];
  title?: string;
  subTitle?: React.ReactNode;
  productCardProps?: Partial<ProductCardProps> & { pageType: ProductCardProps['pageType'] };
  swiperProps?: Partial<SwiperProps>;
  onVisibilityChange?: ({ isVisible, index }: { isVisible: boolean; index: number }) => void;
  cta?: {
    title: string;
    href: string;
    sendClickAnalytics?: () => void;
    rel?: string;
  };
  showAuthGuard?: boolean;
  productType?: string;
  includeProductSchema?: boolean;
  minProductCount?: number;
}

const ProductBlock: React.FC<ProductBlockProps> = (props) => {
  const {
    products,
    title,
    subTitle = null,
    productCardProps,
    swiperProps,
    onVisibilityChange,
    cta,
    showAuthGuard,
    productType,
    includeProductSchema,
    minProductCount = 2,
  } = props;
  const { isWiderThanMd } = useWindowSize();

  const ctaButton = useMemo(
    () =>
      cta ? (
        <Link
          href={cta.href}
          data-cy={`view_all_${productType || 'button'}`}
          onClick={cta.sendClickAnalytics}
          className={clsx(
            styles.productBlockSection__cta,
            'vc-btn vc-btn--secondary vc-btn--large vc-btn--full vc-btn--notfull--md-up',
          )}
          rel={cta.rel}
        >
          {cta.title}
        </Link>
      ) : null,
    [cta, productType],
  );

  if (products.length < minProductCount) {
    return null;
  }

  return (
    <section className={styles.productBlockSection}>
      <div className={clsx('innerContainer', styles.productBlockSection__container)}>
        {(title || subTitle || cta) && (
          <div
            className={styles.productBlockSection__header}
            data-cy="productBlockSection_header"
          >
            <div>
              {title && (
                <h2
                  className={clsx(
                    styles.productBlockSection__title,
                    subTitle && styles['productBlockSection__title--subtitled'],
                  )}
                >
                  {title}
                </h2>
              )}
              {subTitle && <div className={clsx(styles.productBlockSection__subtitle, 'vc-text-l')}>{subTitle}</div>}
            </div>
            {isWiderThanMd && ctaButton}
          </div>
        )}
        <ProductSlider
          showAuthGuard={showAuthGuard}
          products={products}
          productCardProps={productCardProps}
          swiperProps={swiperProps}
          onVisibilityChange={onVisibilityChange}
          includeProductSchema={includeProductSchema}
        />
        {!isWiderThanMd && ctaButton}
      </div>
    </section>
  );
};

export default ProductBlock;
