import { ContentStackComponentKeys } from '@enums/contentStack';
import { FallbackComponentDefinition } from '@constants/metas/default';
import Description from '@components/molecules/description/description';
import Banner from '@components/organisms/banner/banner';
import Campaign from '@components/organisms/campaign/campaign';
import Merchandise from '@components/organisms/merchandise/merchandise';
import PostList from '@components/organisms/post-list/post-list';
import ProductCatalog from '@components/organisms/product-catalog/product-catalog';
import ProductsProviderListComponent from '@components/organisms/product-general-component/product-general-component';
import RecentlyViewed from '@components/organisms/recently-viewed/recently-viewed';
import Channel from '@components/organisms/channel/channel';
import TextContainer from '@components/molecules/text-container/text-container';
import TextComponent from '@components/molecules/text/text-component';
import VideoContainer from '@components/molecules/video-container/video-container';
import VisualContainer from '@components/molecules/visual-container/visual-container';
import Voucher from '@components/organisms/voucher/voucher';
import CampaignHero from '@components/organisms/cms/campaign-hero/campaign-hero';

export const getComponentKey = (type: string, uid: string, index: number) => `${type}-${uid}-${index}`;

const componentMap = {
  [ContentStackComponentKeys.SALE_BLOCK]: CampaignHero,
  [ContentStackComponentKeys.CAMPAIGN_V2]: Campaign,
  // for api - v1 - start
  [ContentStackComponentKeys.CAMPAIGN]: Campaign,
  [ContentStackComponentKeys.SEC_1_CAMPAIGN]: Campaign,
  [ContentStackComponentKeys.SEC_2_CAMPAIGN]: Campaign,

  [ContentStackComponentKeys.CHANNEL_V2]: Channel,
  // for api - v1 - start
  [ContentStackComponentKeys.CHANNEL]: Channel,
  [ContentStackComponentKeys.SEC_1_CHANNEL]: Channel,

  [ContentStackComponentKeys.MERCH_V2]: Merchandise,
  // for api - v1 - start
  [ContentStackComponentKeys.MERCH]: Merchandise,
  [ContentStackComponentKeys.SEC_1_MERCH]: Merchandise,
  [ContentStackComponentKeys.SEC_2_MERCH]: Merchandise,
  [ContentStackComponentKeys.SEC_3_MERCH]: Merchandise,
  [ContentStackComponentKeys.SEC_4_MERCH]: Merchandise,

  [ContentStackComponentKeys.INFO_BANNER_V2]: Banner,
  // for api - v1 - start
  [ContentStackComponentKeys.INFO_BANNER]: Banner,
  [ContentStackComponentKeys.BANNER_CONTAINER]: Banner,

  [ContentStackComponentKeys.POST_FEED_V2]: PostList,
  [ContentStackComponentKeys.POST_FEED]: PostList, // for api - v1

  // for api - v1 - start
  [ContentStackComponentKeys.DESCRIPTION]: Description,
  [ContentStackComponentKeys.DESCRIPTION_V2]: Description,
  // for api - v1 - end

  [ContentStackComponentKeys.PRODUCT_FEED_V2]: ProductCatalog,
  // for api - v1 - start
  [ContentStackComponentKeys.PRODUCT_FEED]: ProductCatalog,
  [ContentStackComponentKeys.PRODUCT_FEED_1]: ProductCatalog,
  [ContentStackComponentKeys.PRODUCT_FEED_2]: ProductCatalog,
  [ContentStackComponentKeys.PRODUCT_FEED_3]: ProductCatalog,
  [ContentStackComponentKeys.PRODUCT_NEW_IN]: ProductCatalog,
  [ContentStackComponentKeys.PRODUCT_WE_LOVE]: ProductCatalog,
  [ContentStackComponentKeys.PRODUCT_CONTAINER]: ProductCatalog,

  [ContentStackComponentKeys.PRODUCT_COMPONENT]: ProductsProviderListComponent,

  [ContentStackComponentKeys.PRODUCT_RECENTLY_VIEWED]: RecentlyViewed,
  // for api - v1 - start

  [ContentStackComponentKeys.TEXT_COMPONENT]: TextComponent,

  [ContentStackComponentKeys.TEXT_CONTAINER]: TextContainer,

  [ContentStackComponentKeys.VIDEO_CONTAINER]: VideoContainer,

  [ContentStackComponentKeys.VOUCHER_BANNER_1]: Voucher,
  [ContentStackComponentKeys.VOUCHER_BANNER_2]: Voucher,
  [ContentStackComponentKeys.VOUCHER_BANNER_3]: Voucher,
  [ContentStackComponentKeys.VOUCHER_BANNER]: Voucher,

  [ContentStackComponentKeys.VISUAL_CONTAINER]: VisualContainer,
  // for api - v1 - end
};

/**
 * Returns React element for component key.
 * @param componentKey Component key (type)
 * @returns
 */
export const getComponentByKey = (componentKey: string): React.ComponentType => {
  return componentMap[componentKey] || null;
};

const sectionComponentTypes = new Set<string>([
  ContentStackComponentKeys.CONTENT_SECTION_1,
  ContentStackComponentKeys.CONTENT_SECTION_2,
  ContentStackComponentKeys.MODULAR_BLOCK,
  ContentStackComponentKeys.SEC_2_CONTAINER,
]);

export const isSectionComponentType = (componentType: string | undefined) =>
  componentType && sectionComponentTypes.has(componentType);

/**
 * Builds a key-value pair of component UID and function that returns fallback component.
 * @param defs Fallback component definitions.
 * @returns
 */
export const buildFallbackComponents = (defs: FallbackComponentDefinition[]): { [id: string]: React.ComponentType } =>
  defs.reduce((acc, { type, data }, i) => {
    const Component = getComponentByKey(type);
    if (!Component) {
      return acc;
    }

    // TODO: add props normalization if necessary in future
    const { uid } = data;
    const key = getComponentKey(type, uid, i);
    const props = {
      ...data,
      key,
      pageType: type,
      componentID: key,
      sectionIdx: i.toString(),
    };

    acc[uid] = () => <Component {...props} />;

    return acc;
  }, {});
