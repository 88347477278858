import React, { useCallback } from 'react';
import clsx from 'clsx';
import useOnReveal from '@hooks/use-on-reveal';
import sanitizer from '@helpers/sanitizer';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import Icon from '@components/atoms/icon/icon';
import styles from './voucher.module.scss';

type VoucherProps = {
  uid: string;
  title: string;
  product_type: string;
  displayTitle: string;
  arrangement: string;
  subtitle?: string;
  sectionIdx: string;
  backgroundColor: string;
  textColor: string;
  ctaText?: string;
  url: string;
};

const Voucher: React.FC<VoucherProps> = ({
  uid,
  displayTitle,
  subtitle,
  backgroundColor,
  textColor,
  ctaText,
  url,
  title,
  sectionIdx,
}): React.JSX.Element => {
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        category: 'cms_content_view',
        action: 'voucher_banner_component',
        property: `${uid}_${title}`,
        label: sectionIdx ?? '',
      });
    }, []),
    0.5,
    false,
  );

  const voucherCmsStyle = {
    backgroundColor: backgroundColor,
    color: textColor,
  };

  const trackVoucherClick = (e) => {
    e.stopPropagation();
    sendAnalyticEvent('cms_content_click', {
      category: 'cms_content_click',
      action: 'voucher_banner_component',
      property: `${uid}_${title}`,
      label: sectionIdx ?? '',
    });
  };

  if (!displayTitle) {
    return null;
  }

  return (
    <section
      ref={containerRef}
      className={clsx('innerContainer', styles.voucher)}
    >
      <a
        href={url}
        onClick={trackVoucherClick}
      >
        <div
          className={styles.voucher_container}
          style={voucherCmsStyle}
        >
          <div className={styles.voucher_container__title}>{displayTitle}</div>
          {subtitle && (
            <div
              className={styles.voucher_container__subtitle}
              dangerouslySetInnerHTML={{ __html: sanitizer(subtitle) }}
            />
          )}
          {ctaText && (
            <div className={styles.voucher_container__cta}>
              <div className={styles.voucher_container__cta__text}>{ctaText}</div>
              <div className={styles.voucher_container__cta__button}>
                <Icon
                  className={styles.voucher_container__arrow}
                  name="arrow-right-long-20"
                />
              </div>
            </div>
          )}
        </div>
      </a>
    </section>
  );
};

export default Voucher;
