import { axios } from '@config/axios';
import Environment from '@config/index';
import { ContentStackPageNames } from '@enums/contentStack';
import { IContentTypeEntries } from '@constants/metas/default';
import logger from '@helpers/utils/logger/client';
import { convertResponseData } from '@helpers/utils/cms/cms-fields-mapping';
import { Preferences } from '@interfaces/models/preferences';

type CmsUserInfo = Partial<Preferences> & {
  userId?: string | null;
  serverAnonymousId?: string;
  csBranch?: string;
};

type ContentStackParams = {
  pageSlug?: string;
  pageUrl?: string;
  campaignId?: string;
  serverAnonymousId?: string;
  cmsFallback?: boolean;
};

type CMSLivePreviewParams = {
  entryId: string;
  contentType: string;
  hash: string;
  locale?: string;
  preferences?: CmsUserInfo;
  filters?: {
    [key in string]: string;
  };
};

export default class ContentStackService {
  static apiBaseUrl = Environment.apiBaseUrl;

  private static getHeaders(preference: CmsUserInfo) {
    const { locale, country, siteId, language, currency, userId, serverAnonymousId, csBranch } = preference;
    const lang = locale.toLowerCase();

    return {
      'x-metadata': serverAnonymousId ? serverAnonymousId : '',
      'x-lang-code': lang,
      'x-country': country,
      'user-agent': 'FENX Server',
      'x-vc-siteid': siteId,
      'x-vc-language': language,
      'x-vc-currency': currency,
      'x-consumer-custom-id': userId || 'anonymous',
      'cs-branch': csBranch || 'main',
    };
  }

  static async getDataForCMSPage(pageName, preference: CmsUserInfo, queryParams?: ContentStackParams) {
    try {
      const { pageSlug, pageUrl, campaignId, cmsFallback } = queryParams || {};

      let url: string;

      switch (pageName) {
        case ContentStackPageNames.CAMPAIGN_PAGE: {
          url = `${this.apiBaseUrl}/contents/cms/cs/campaigns/${campaignId}`;

          break;
        }
        default: {
          url = `${this.apiBaseUrl}/contents/cms/cs/pages/${pageName}`;

          if (pageSlug) {
            url += `?pageId=${this.extractPageId(pageSlug)}`;
          } else if (pageUrl) {
            url += `?pageUrl=${pageUrl}`;
          }
        }
      }

      // TODO: The cmsFallback check is intended for QA testing purposes only.
      const urlWithFallback = cmsFallback ? `${url}fallbacktesting` : url;

      const response = await axios.get(urlWithFallback, {
        headers: ContentStackService.getHeaders(preference),
      });

      return convertResponseData(response?.data?.data) || {};
    } catch (err) {
      logger.error(err, 'Error occurred at action: getDataForCMSPage - content-stack-service.tsx');
      throw err;
    }
  }

  static async getDataForCMSLivePreview(livepreviewParams: CMSLivePreviewParams): Promise<IContentTypeEntries> {
    try {
      const { entryId, contentType, hash, locale, preferences, filters } = livepreviewParams;
      let apiUrl = `${this.apiBaseUrl}/contents/cms/cs/entries/${entryId}/content_types/${contentType}/hash/${hash}?locale=${locale}`;

      if (filters) {
        Object.keys(filters).forEach((filter) => {
          apiUrl += `&${filter}=${filters[filter]}`;
        });
      }

      const response = await axios.get(apiUrl, {
        headers: ContentStackService.getHeaders(preferences),
      });

      return convertResponseData(response?.data?.data) || {};
    } catch (err) {
      logger.error(err, 'Error occurred at action: getDataForCMSLivePreview - content-stack-service.tsx');
      throw err;
    }
  }

  static async getDataForCMSPagePreview(entryId: string, preferences: CmsUserInfo): Promise<IContentTypeEntries> {
    const headers = {
      ...ContentStackService.getHeaders(preferences),
      'x-support-type': 'web',
    };
    delete headers['user-agent'];

    try {
      const apiUrl = `${this.apiBaseUrl}/v2/contents/cms/cs/pages/url/${entryId}`;
      const response = await axios.get(apiUrl, {
        headers: headers,
      });

      return convertResponseData(response?.data?.data?.entry || response?.data?.data) || {};
    } catch (err) {
      logger.error(err, 'Error occurred at action: getDataForCMSLivePreview - content-stack-service.tsx');
      throw err;
    }
  }

  private static extractPageId(pageSlug: string): string {
    if (!pageSlug) {
      return '';
    }

    const pageSlugArr = pageSlug.split('-');
    const pageId = pageSlugArr[pageSlugArr.length - 1];

    return pageId;
  }
}
