import React from 'react';
import Link from '@components/atoms/link/link';
import Button from '@components/atoms/button/button';
import { EditoPageCTA } from '@interfaces/models/editorialPage';
import styles from '../visual-container.module.scss';

interface VisualContainerCTAProps {
  innerStyle: {
    backgroundColor?: string;
    borderColor?: string;
    color?: string;
  };
  cta: EditoPageCTA['cta'];
}

const VisualContainerCTA = ({ innerStyle, cta }: VisualContainerCTAProps) => {
  const handleOnClick = (e) => {
    if (cta?.href) {
      e.preventDefault();
      if (typeof window !== 'undefined') {
        window.location.assign(cta?.href);
      }
    }
  };

  return (
    <div className={styles.visualcontainer_cta_module_cta}>
      <Link href={cta?.href}>
        <Button
          variant="primary"
          size="medium"
          fullWidth
          notFullWidthQuery="md-up"
          style={innerStyle}
          onClick={handleOnClick}
        >
          {cta?.title}
        </Button>
      </Link>
    </div>
  );
};

export default VisualContainerCTA;
