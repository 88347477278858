import { useEffect, useState } from 'react';
import { ElasticSearchFacetsFields } from '@interfaces/models/elasticSearch';
import { isDirectShippingPage, isExpressDeliveryPage } from '@helpers/routing';

export type StaticFilterGroups = Partial<{ [key in ElasticSearchFacetsFields]: boolean }>;

const INITIAL_STATIC_FILTERS = {
  universe: false,
  categoryLvl0: false,
  categoryLvl1: false,
  categoryLvl2: false,
  brand: false,
  stock: false,
  model: false,
  directShippingEligible: false,
};

const useStaticFilterGroups = () => {
  const [staticFilterGroups, setStaticFilterGroups] = useState<StaticFilterGroups>(INITIAL_STATIC_FILTERS);

  useEffect(() => {
    const path = window.location.pathname;
    if (isExpressDeliveryPage(path)) {
      setStaticFilterGroups((prevState) => ({
        ...prevState,
        stock: true,
      }));
    }
    if (isDirectShippingPage(path)) {
      setStaticFilterGroups((prevState) => ({
        ...prevState,
        directShippingEligible: true,
      }));
    }
  }, []);

  return { staticFilterGroups, setStaticFilterGroups };
};

export default useStaticFilterGroups;
