import React, { useMemo, useCallback } from 'react';
import useOnReveal from '@hooks/use-on-reveal';
import clsx from 'clsx';
import CTAElement from '@components/molecules/text-container/components/CTAElement';
import { EditoPageCTA } from '@interfaces/models/editorialPage';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import styles from './text-container.module.scss';

type TextContainerProps = {
  body: string;
  ctas?: Array<EditoPageCTA>;
  _metadata: { uid: string };
  label: string;
  body_s_title: string;
  tracking_id: string;
  sectionIdx?: string;
  ml_body: string;
};

const TextContainer: React.FC<TextContainerProps> = (props) => {
  const { ctas, label, sectionIdx, ml_body: body, _metadata, body_s_title: title, tracking_id: contentTypeUID } = props;
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        category: 'cms_content_view',
        action: contentTypeUID,
        property: `${_metadata?.uid}_${label}`,
        value: String(sectionIdx || ''),
      });
    }, [contentTypeUID, label, sectionIdx, _metadata?.uid]),
    0.5,
    false,
  );

  const handleOnClickCTA = (cta, _metadata, idx) => {
    sendAnalyticEvent('cms_content_click', {
      category: 'cms_content_click',
      action: contentTypeUID,
      property: `${_metadata?.uid}_${label}`,
      value: String(idx || ''),
    });

    if (!cta?.href) {
      return null;
    }

    window.location.href = cta?.href;
  };

  const ctaElements = useMemo(() => {
    if (!ctas || ctas.length < 1) {
      return null;
    }

    return ctas?.map(({ cta, _metadata, cta_s_background_color, cta_s_text_color }, index) => {
      const { uid } = _metadata || {};
      const key = uid + index;

      return (
        <CTAElement
          key={key}
          cta={cta}
          handleOnClick={handleOnClickCTA}
          metadata={_metadata}
          index={index}
          bgColor={cta_s_background_color}
          titleColor={cta_s_text_color}
        />
      );
    });
  }, [ctas]);

  return (
    <section ref={containerRef}>
      <div className="innerContainer">
        <div className={styles.textcontainer}>
          <div className={styles.textcontainer_text_module}>
            {title && <h3 className={clsx('vc-title-s', styles.textcontainer_title)}>{title}</h3>}
            {body && <p className={clsx('vc-text-m', styles.main_text)}>{body}</p>}
            {ctas?.length >= 1 && <div className={styles.textcontainer_cta_module}>{ctaElements}</div>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextContainer;
