import { Negotiation } from '@interfaces/models/negotiation';
import React, { FCWithChildren, useContext, useState } from 'react';
import { User } from '@interfaces/models/user';

interface NegotiationContextProps {
  negotiation: Negotiation;
  setNegotiation: React.Dispatch<React.SetStateAction<Negotiation>>;
  setNegotiationByAuthor: (authorId: User['id']) => void;
  negotiationList: Negotiation[];
  setNegotiationList: React.Dispatch<React.SetStateAction<Negotiation[]>>;
}

export type NegotiationProviderProps = {
  negotiation?: Negotiation;
};

const NegotiationStateContext = React.createContext<NegotiationContextProps>(undefined);

const NegotiationProvider: FCWithChildren<NegotiationProviderProps> = (props) => {
  const { negotiation: defaultNegotiation, children } = props;

  const [negotiation, setNegotiation] = useState<Negotiation>(defaultNegotiation);
  const [negotiationList, setNegotiationList] = useState<Negotiation[]>([]);

  const setNegotiationByAuthor = (authorId: User['id']): void => {
    setNegotiation(negotiationList.find((negotiation) => negotiation.buyer.id === authorId) || null);
  };

  const value = {
    negotiation,
    setNegotiation,
    setNegotiationByAuthor,
    negotiationList,
    setNegotiationList,
  };
  return <NegotiationStateContext.Provider value={value}>{children}</NegotiationStateContext.Provider>;
};

const useNegotiation = () => useContext(NegotiationStateContext);

export { NegotiationProvider, useNegotiation };
