import { axios, getAxiosConfig, ServerAxiosRequestData } from '@config/axios';
import Environment from '@config/index';
import { ApiResponse } from '@interfaces/api';
import { Product } from '@interfaces/models/product';
import { User } from '@interfaces/models/user';

export type SortOrder = 'price' | 'recentPriceDrop' | 'priceDesc' | 'date' | 'dateDesc' | null;

export const FAV_DEFAULT_SORT_ORDER = 'dateDesc';

type GetProductsPayload = {
  userId: User['id'];
  order: string;
  limit: number;
  offset: number;
};

const BASE_URL = `${Environment.apiBaseUrl}/users`;

export const getUsersFavoritesProducts = async (
  payload: GetProductsPayload,
  serverAxiosRequestData?: ServerAxiosRequestData,
): Promise<ApiResponse<Product[]>> => {
  const { userId, order, limit, offset } = payload;
  const axiosConfig = getAxiosConfig(serverAxiosRequestData);

  axiosConfig.params = {
    ...axiosConfig.params,
    order,
    limit,
    offset,
  };

  const { data } = await axios.get<ApiResponse<Product[]>>(`${BASE_URL}/${userId}/liked-products`, axiosConfig);
  return data;
};
