import { CatalogProduct } from '@interfaces/models/catalogProduct';
import { Product } from '@interfaces/models/product';
import { pickSearchContextAttributes } from '@components/catalog/catalog-datalayer/catalog-datalayer';
import { getOrCreateLDCookies } from '@components/bucketTest/bucket.utils';

export const getProductTrackingData = (product: CatalogProduct | Product) => ({
  product_id: String(product.id),
  seller_id: product.seller?.id ? product.seller?.id : '',
  product_universe: 'universeId' in product ? String(product.universeId) : '',
  product_category: 'category' in product ? String(product.category.localizedName) : '',
  product_sub_category: 'subcategory' in product ? String(product.subcategory.localizedName) : '',
  product_brand: product.brand?.name,
  product_unitprice_ati: String((product.price?.cents ?? 0) / 100 ?? ''),
  product_country: product.isoCountry ?? '',
  product_card_tag: 'isNewProduct' in product && product.isNewProduct ? 'NEW' : product.tags?.join(',') ?? '',
  currency: product.price?.currency ?? '',
});

export const getCatalogTrackingData = () =>
  pickSearchContextAttributes([
    'keyword',
    'index_name',
    'catalog_brand',
    'catalog_universe',
    'catalog_category',
    'catalog_subcategory',
    'campaign_id',
    'cms_block_id',
    'filters_applied',
    'nb_results',
    'filters_details',
    'search_query_id',
  ]);

export const getUserToken = (userId: string | undefined) => {
  const { anonymousId } = getOrCreateLDCookies();
  return userId || `anonymous-${anonymousId}`;
};
