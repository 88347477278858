import { CatalogContextProps } from '@context/catalog.context';
import { CatalogFilterConfiguration } from '@interfaces/models/catalogFilter';
import filterGroupConfigurations from '@config/catalog/filter-group-configurations';
import { ElasticSearch, ElasticSearchFilterKeys } from '@interfaces/models/elasticSearch';
import { getCountryNameFromCountryISOCode } from '@helpers/utils/general';
import { AvailableCountryISOCodes } from '@interfaces/models/country';
import { filterToFacetRename } from '@maps/catalog/search-product-facets-filters-maps';
import { useMemo } from 'react';
import { booleanFilters } from '@constants/catalog';
import mapModels from '@maps/catalog/map-models';
import { Chip } from '@components/catalog/chip-list/chip-list';
import { useTranslation } from 'next-i18next';
import useLocalizationQuery from '@hooks/localization/use-localization';
import useMarketingFiltersQuery from '@hooks/marketing-filters/use-marketing-filters-query';
import useActiveDealQuery from '@hooks/deal/use-active-deal-query';
import { usePreferences } from '@context/preferences.context';
import { formatPrice } from '@helpers/utils/price-formatter-helper';
import { ContentsMarketingFilter } from '@interfaces/models/contentsMarketingFilter';

interface UseChipList {
  filters: CatalogContextProps['filters'];
  facets: CatalogContextProps['facets'];
  staticFilterGroups: CatalogContextProps['staticFilterGroups'];
}

const PSEUDO_FILTERS = ['catalogLinksWithoutLanguage', 'directShippingCountries'];

export const getLocalCountriesLabel = ({
  freeShippingFilter,
  localToLocalFilter,
}: {
  freeShippingFilter: ContentsMarketingFilter;
  localToLocalFilter: ContentsMarketingFilter;
}): string => {
  return localToLocalFilter?.title ?? freeShippingFilter?.title;
};

// TODO: Cleanup & test the logic in this hook, it's quite messy...
const useChipList = ({ filters, facets, staticFilterGroups }: UseChipList) => {
  const { t } = useTranslation();
  const { activeDeal } = useActiveDealQuery();
  const { countryList } = useLocalizationQuery();
  const { freeShippingFilter, localToLocalFilter } = useMarketingFiltersQuery();
  const { currency, language } = usePreferences();

  const filterGroupsConfiguration: CatalogFilterConfiguration[] = filterGroupConfigurations;

  // Some labels require special rules
  const getItemLabel = (filterGroup: ElasticSearchFilterKeys, filterId?: string): string => {
    if (filterGroup === 'country') {
      return getCountryNameFromCountryISOCode(countryList, filterId as AvailableCountryISOCodes);
    } else if (filterGroup === 'dealEligible') {
      return activeDeal?.localizedName;
    } else if (filterGroup === 'sold') {
      const [filterConfig] = filterGroupsConfiguration.filter((item) => item.facet === filterGroup);
      return `${t(filterConfig.itemText[filterId])}`;
    }
    const facetName = filterToFacetRename(filterGroup);
    // Find the name in the facets for the rest
    const facetData = facets[facetName].find((facet) => facet.id === filterId);
    return facetData?.name;
  };

  const getLabels = (filters: ElasticSearch['filters'], filterGroup: ElasticSearchFilterKeys): Chip[] => {
    const result: Chip[] = [];
    const facetName = filterToFacetRename(filterGroup);
    (filters[filterGroup] as string[]).forEach((filterId) => {
      if (Array.isArray(facets[facetName]) && facets[facetName].some((facet) => facet.id === filterId)) {
        result.push({
          label: getItemLabel(filterGroup, filterId),
          filterGroup,
          filterIds: [filterId],
        } as Chip);
      }
    });
    return result;
  };

  return useMemo(() => {
    if (!filters || !facets || !Object.keys(facets).length) {
      return [];
    }

    const activeFiltersGroups = Object.keys(filters).filter(
      (filterKey: ElasticSearchFilterKeys) =>
        !staticFilterGroups[filterToFacetRename(filterKey)] && !PSEUDO_FILTERS.includes(filterKey),
    ) as ElasticSearchFilterKeys[];

    return activeFiltersGroups
      .map((filterGroup: ElasticSearchFilterKeys) => {
        // For boolean filters, show <FilterGroup> title, for others, show name
        // TODO: Fix types
        // @ts-ignore
        if (booleanFilters.includes(filterGroup)) {
          const filterGroupData = filterGroupsConfiguration.find(
            (filterGroupConfig) => filterGroupConfig.facet === filterGroup,
          );
          if (filterGroup === 'localCountries') {
            return {
              label: getLocalCountriesLabel({ localToLocalFilter, freeShippingFilter }) ?? t(filterGroupData.label),
              filterGroup,
            };
          }
          // Display only for dealEligible facet
          if (filterGroup === 'dealEligible') {
            return {
              label: getItemLabel('dealEligible'),
              filterGroup,
            };
          }
          return {
            label: t(filterGroupData.label),
            filterGroup,
          };
        }

        // Display only the unique model labels
        if (filterGroup === 'model.id' && facets.model) {
          const uniqueModelIds = mapModels(facets.model).map((model) => model.id);

          return filters['model.id']
            .filter((id) => uniqueModelIds.includes(id))
            .map((id) => {
              return {
                label: getItemLabel('model.id', id),
                filterIds: [id],
                filterGroup,
              };
            });
        }

        // Price is a special filter, so we'll handle it here
        if (filterGroup === 'price') {
          const priceChips: Chip[] = [];
          const price = filters.price;

          if (price.hasOwnProperty('>=')) {
            const label: string = `${t('CATALOG.FILTERS.PRICE.MIN')} ${formatPrice(
              Math.floor(price['>='] / 100),
              currency,
              language,
            )}`;
            priceChips.push({
              label,
              filterIds: ['>='],
              filterGroup,
            });
          }

          if (price.hasOwnProperty('<=')) {
            const label: string = `${t('CATALOG.FILTERS.PRICE.MAX')} ${formatPrice(
              Math.floor(price['<='] / 100),
              currency,
              language,
            )}`;
            priceChips.push({
              label,
              filterIds: ['<='],
              filterGroup,
            });
          }

          return priceChips;
        }

        if (['categoryLvl0.id', 'categoryLvl1.id', 'categoryLvl2.id'].includes(filterGroup)) {
          const categoryChips = getLabels(filters, filterGroup);
          return categoryChips.reduce((acc, chip) => {
            const foundChip = acc.find((accChip) => accChip.label === chip.label);
            if (foundChip) {
              foundChip.filterIds = foundChip.filterIds.concat(chip.filterIds);
              return acc;
            }
            return [...acc, chip];
          }, [] as Chip[]);
        }
        // Numeric & string array filters
        return getLabels(filters, filterGroup);
      })
      .flat() as Chip[];
  }, [filters, facets, staticFilterGroups]);
};

export default useChipList;
