import { SEO } from '@interfaces/models/seo';
import { ContentsPageMetadata } from '@interfaces/models/contentsPageMetadata';
import { CMS_LANDING_PAGES, ContentStackComponentKeys, ContentStackPageNames } from '@enums/contentStack';
import { Preferences } from '@interfaces/models/preferences';
import { SupportedLanguages } from '@interfaces/models/language';

export interface ICMSTracking {
  tracking: string;
}
export interface FallbackComponentDefinition {
  type: ContentStackComponentKeys;
  data: IContentTypeEntries;
}
export interface IContentTypeEntries {
  uid: string;
  id?: string;
  title: string;
  url: string;
  seo: SEO;
  displayTitle: string;
  contentTypeId: ContentStackPageNames;
  tracking: ICMSTracking | string;
  fallback_components?: FallbackComponentDefinition[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

// This file contains the defaults meta tags injected when no other available metas are present
export const defaultMetas: Record<SupportedLanguages, { title: string; description: string }> = {
  fr: {
    title: "Vestiaire Collective : Dépot vente luxe, mode d'occasion",
    description:
      "Leader mondial de la mode d'occasion, nous proposons un catalogue inspirant de plus 700.000 articles et plus 5.200 marques. ✅ Jusqu'à -70% du prix neuf !",
  },
  en: {
    title: 'Luxury resale store, find pre-owned fashion on Vestiaire Collective',
    description:
      'VestiaireCollective.com, the best of pre-owned fashion at 30 to 70% off original retail price. ✅ Buy! Sell! Share! ✅ 100% of the items are curated and manually controlled by our experts!',
  },
  it: {
    title: 'La migliore selezione di capi di moda sino a -70% del prezzo in negozio! - Vestiaire Collective',
    description:
      'Vestiaire Collective, il miglior marketplace di moda second-hand con prezzi fino al 70% in meno rispetto a quelli in boutique. ✅ Acquista, Vendi, Condividi! ✅ 100% degli articoli sono selezionati e controllati dai nostri esperti!',
  },
  de: {
    title: 'Vestiaire Collective : Kaufen und verkaufen Sie Premium- und Luxusmode aus zweiter Hand',
    description:
      'VestiaireCollective, die beste Secondhand- Mode für 30- bis 70% weniger als der originale Kaufpreis. ✅ Kaufen! Verkaufen! Teilen! ✅ 100% aller Artikel sind von unseren Experten kuratiert und manuell überprüft!',
  },
  es: {
    title: 'Vestiaire Collective : El mejor sitio donde intercambiar ropa del mundo. Compra, vende y recicla',
    description:
      'Vestiairecollective.com, ¡la plataforma de compraventa de lujo! ✅ ¡Compra, vende y vacía tu armario! ✅ ¡Encuentra el artículo de tus sueños! Nuestros equipos seleccionan y examinan todos nuestros artículos.',
  },
  us: {
    title: 'Luxury resale store, find pre-owned fashion on Vestiaire Collective',
    description:
      'VestiaireCollective.com, the best of pre-owned fashion at 30 to 70% off original retail price. ✅ Buy! Sell! Share! ✅ 100% of the items are curated and manually controlled by our experts!',
  },
  ko: {
    title: '명품 리세일 스토어 베스티에르 콜렉티브에서 중고 패션 상품을 찾아보세요',
    description:
      'VestiaireCollective.com, 최고의 중고 패션 플랫폼으로 리테일가에서 30%에서 최대 70% 가격의 상품을 찾을 수 있습니다. ✅ 구매! 판매! 공유! ✅ 100%의 상품들이 전문가들에의하 직접 큐레이션되고 관리됩니다!',
  },
  hk: {
    title: '豪華轉售店，於Vestiaire Collective尋找二手時尚。',
    description:
      'VestiaireCollective.com ，頂尖的二手時尚平台，享受原價七至三折的優惠。✅ 購買！出售！分享！✅ 所有物品均由我們的專家精心挑選和手動控制！',
  },
  sv: {
    title: 'Lyxsecondhandbutik, hitta förägda modetrender på Vestiaire Collective.',
    description:
      'VestiaireCollective.com , det bästa inom begagnad mode till 30-70% rabatt på originalpriset. ✅ Köp! Sälj! Dela! ✅ Alla objekt kurateras och kontrolleras manuellt av våra experter!',
  },
  nl: {
    title: 'Luxe tweedehands winkel, vind tweedehands mode op Vestiaire Collective.',
    description:
      'VestiaireCollective.com , het beste op het gebied van tweedehands mode met 30 tot 70% korting op de oorspronkelijke winkelprijs. ✅ Koop! Verkoop! Deel! ✅ 100% van de items wordt zorgvuldig geselecteerd en handmatig gecontroleerd door onze experts!',
  },
};

export const defaultPageMetas: Record<
  SupportedLanguages,
  { title: string; description: string; keywords: string; searchTitle: string; searchDescription: string }
> = {
  fr: {
    title: 'Vestiaire Collective : acheter et vendre des pièces de designer de seconde main.',
    description:
      "3 millions d'articles, plus de 12 000 marques. La mode seconde-main d'occasion jusqu'à -70%. Authentification par des experts, à laquelle des millions de personnes font confiance.",
    keywords:
      "Vestiaire Collective, marques de luxe, vêtements de créateurs d'occasion, mode, montres de luxe, sacs à main, chaussures en ligne, bijoux, vintage, femmes, hommes",
    searchTitle: ' searchValue - Mode de Seconde Main !',
    searchDescription:
      "Laissez-vous séduire par notre collection de searchValue, ainsi qu’un grand choix d'articles de luxe ! ✅ Jusqu'à -70% du prix du neuf ! ✅Paiement 3 fois ✅ Livraison rapide",
  },
  en: {
    title: 'Vestiaire Collective: Buy & sell designer second-hand fashion.',
    description:
      '3 million items, 12,000+ brands. Luxury pre-loved fashion, up to 70% off. Expert authentication, trusted by millions.',
    keywords:
      'Vestiaire Collective, luxury brands, second hand designer clothes, fashion, luxury watches, handbags, shoes online, jewelry, vintage, women, men',
    searchTitle: ' searchValue - Second Hand Fashion !',
    searchDescription:
      'Let yourself be seduced by our collection of searchValue, as well as a large selection of luxury items! ✅ Up to -70% off of the retail price! ✅ 3 time payments ✅ Fast delivery',
  },
  it: {
    title: 'Vestiaire Collective: compra e vendi moda firmata di seconda mano.',
    description:
      '3 milioni di articoli, più di 10.000 brand. Moda di lusso di seconda mano con sconti fino al 70%. Servizio di autenticazione professionale a cui si affidano milioni di utenti.',
    keywords:
      'Vestiaire Collective, marchi di lusso, abiti firmati di seconda mano, moda, orologi di lusso, borse, scarpe online, gioielli, vintage, donne, uomini',
    searchTitle: ' searchValue - Moda della Seconda mano !',
    searchDescription:
      'Lasciati sedurre dalla nostra collezione di searchValue e da una vasta selezione di articoli di lusso! ✅ Fino al -70% del prezzo del nuovo! ✅ Pagamento in 3 volte ✅ Consegna rapida',
  },
  de: {
    title: 'Vestiaire Collective: Second-Hand-Mode kaufen und verkaufen',
    description:
      '3 Millionen Artikel von über 12 Tsd. Marken. Luxusmode aus zweiter Hand bis zu -70%. Professionelle Authentifizierung.',
    keywords:
      'Vestiaire Collective, Luxusmarken, Second-Hand-Designer-Kleidung, Mode, Luxusuhren, Handtaschen, Schuhe online, Schmuck, Vintage, Frauen, Männer',
    searchTitle: ' searchValue - Second Hand Mode !',
    searchDescription:
      'Lassen Sie sich von unserer Kollektion searchValue sowie einer großen Auswahl an Luxusartikeln verführen! ✅ Bis zu -70% des Neupreises! ✅ Ratenzahlung möglich ✅ Schnelle Lieferung',
  },
  es: {
    title: 'Vestiaire Collective: compra y vende moda de marca de segunda mano.',
    description:
      '3 millones de artículos, más de 10 000 marcas. Moda de lujo pre-loved con hasta -70%. Servicio de autentificación profesional.',
    keywords:
      'Vestiaire Collective, marcas de lujo, ropa de diseñador de segunda mano, moda, relojes de lujo, bolsos, zapatos en línea, joyas, vintage, mujeres, hombres',
    searchTitle: ' searchValue - Moda de Segunda Mano !',
    searchDescription:
      '¡Déjese seducir por nuestra colección de searchValue, así como por una gran selección de artículos de lujo! ✅ ¡Hasta -70% del precio nuevo! ✅ Pago en 3 veces ✅ Envío rápido',
  },
  us: {
    title: 'Vestiaire Collective: Buy & sell designer second-hand fashion.',
    description:
      '3 million items, 12,000+ brands. Luxury pre-loved fashion, up to 70% off. Expert authentication, trusted by millions.',
    keywords:
      'Vestiaire Collective, luxury brands, second hand designer clothes, fashion, luxury watches, handbags, shoes online, jewelry, vintage, women, men',
    searchTitle: ' searchValue - Second Hand Fashion !',
    searchDescription:
      'Let yourself be seduced by our collection of searchValue, as well as a large selection of luxury items! ✅ Up to -70% off of the retail price! ✅ 3 time payments ✅ Fast delivery',
  },
  ko: {
    title: '베스티에르 콜렉티브: 글로벌 패션 리세일 플랫폼 Vestiaire Collective',
    description:
      '전 세계 2,300만 회원의 세계 최대 패션 커뮤니티. 80개국에서 모인 10,000개 이상의 브랜드, 300만개의 아이템을 지금 만나보세요.',
    keywords:
      '베스티에르 콜렉티브, 명품, 중고 명품, 디자이너 중고, 패션, 명품백, 명품시계, 명품 구두, 명품 주얼리, 빈티지, 여성 패션, 남성 패션',
    searchTitle: 'searchValue -  지금 베스티에르 콜렉티브에서 찾아보세요',
    searchDescription:
      'searchValue는 물론 다양한 럭셔리 브랜드를 정가 최대 70% 할인가에 구매하세요✅ 10년 이상 노하우의 정품 감정 센터에서 전문가가 직접 감정합니다.',
  },
  hk: {
    title: 'Vestiaire Collective：購買和出售二手設計師時尚。',
    description: '3百萬款商品，12,000多個品牌。奢華二手時尚，高達七折優惠。專業驗證，得到數百萬用戶的信任。',
    keywords: 'Vestiaire Collective，奢侈品牌，二手設計師服飾，時尚，豪華手錶，手袋，線上鞋履，珠寶，復古，女裝，男裝',
    searchTitle: 'searchValue - 二手時尚！',
    searchDescription:
      '讓自己沉醉於我們的searchValue系列，以及豐富的奢侈品選擇吧！✅高達零售價的70％折扣！✅分三次付款✅快速送貨',
  },
  sv: {
    title: 'Vestiaire Collective: Köp och sälj begagnad designermode.',
    description:
      '3 miljoner artiklar, 12 000+ varumärken. Lyxig förälskad mode, upp till 70% rabatt. Expertautentisering, förtroende från miljontals användare.',
    keywords:
      'Vestiaire Collective, lyxmärken, second hand-designkläder, mode, lyxklockor, handväskor, skor online, smycken, vintage, kvinnor, män',
    searchTitle: 'searchValue - Second Hand Mode!',
    searchDescription:
      'Låt dig förföras av vår kollektion av searchValue, samt ett stort urval av lyxartiklar! ✅ Upp till -70% rabatt på ordinarie pris! ✅ Betalning i tre delar ✅ Snabb leverans',
  },
  nl: {
    title: 'Vestiaire Collective: Koop en verkoop tweedehands mode van ontwerpers.',
    description:
      '3 miljoen items, 12.000+ merken. Luxe tweedehands mode, tot 70% korting. Expert-authenticatie, vertrouwd door miljoenen.',
    keywords:
      'Vestiaire Collective, luxemerken, tweedehands ontwerpkleding, mode, luxe horloges, handtassen, schoenen online, sieraden, vintage, vrouwen, mannen',
    searchTitle: 'searchValue - Tweedehands Mode!',
    searchDescription:
      'Laat je verleiden door onze collectie van searchValue, evenals een grote selectie van luxe items! ✅ Tot -70% korting op de winkelprijs! ✅ Betalen in 3 termijnen ✅ Snelle levering',
  },
};

export const getPageMeta = (
  arg: SEO,
  language: Preferences['language'],
  pageName?: ContentStackPageNames,
  custom?: { title?: string; description?: string },
): ContentsPageMetadata => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    keywords: defaultKeywords,
  } = defaultPageMetas[language] || {};

  const {
    robots: seoRobots = '',
    seo_title: seoTitle = '',
    canonical_url: canonicalUrl = '',
    meta_description: seoDescription = '',
  } = arg || {};

  const { title: pageTitle, description: pageDescription } = custom || {};
  let seoMetas = {
    title: seoTitle || defaultTitle,
    description:
      seoDescription ||
      (pageDescription && pageDescription.length > 165 ? pageDescription.substring(0, 162) + '...' : pageDescription) ||
      defaultDescription,
    keywords: defaultKeywords,
    robots:
      ContentStackPageNames.CAMPAIGN_PAGE === pageName
        ? seoRobots || ('' as SEO['robots'])
        : seoRobots || 'index, follow',
    canonicalUrl,
  };

  if (CMS_LANDING_PAGES.includes(pageName)) {
    seoMetas = {
      ...seoMetas,
      title: seoTitle || pageTitle || defaultTitle,
      robots: seoRobots || 'noindex',
    };
  } else if ([ContentStackPageNames.EDITORIAL_PAGE, ContentStackPageNames.CAMPAIGN_PAGE].includes(pageName)) {
    const seoTitleSuffix = ContentStackPageNames.CAMPAIGN_PAGE
      ? ' - Vestiaire Collective'
      : ' - Journal | Vestiaire Collective';
    seoMetas = {
      ...seoMetas,
      title: seoTitle || (pageTitle && pageTitle + seoTitleSuffix) || defaultTitle,
    };
  }

  return seoMetas;
};

export const defaultPageMetasForBrandPage: Record<
  SupportedLanguages,
  { title: string; description: string; headingTitle: string }
> = {
  fr: {
    title: 'Découvrez les marques de créateurs de luxe sur Vestiaire Collective',
    description:
      'Une sélection de marques emblématiques, des classiques intemporels aux créateurs de mode vintage et contemporains. Découvrez le style et la sophistication dans notre assortiment exclusif.',
    headingTitle: 'Découvrez les marques de créateurs de luxe de A à Z',
  },
  en: {
    title: 'Explore Luxury Designer Brands at Vestiaire Collective',
    description:
      'A curated selection of iconic brands, from timeless classics to vintage and contemporary fashion designers. Uncover the style and sophistication in our exclusive assortment.',
    headingTitle: 'Discover Luxury Designer Brands A-Z',
  },
  it: {
    title: 'Esplora i marchi di stilisti di lusso su Vestiaire Collective',
    description:
      'Una selezione curata di marchi iconici, dai classici senza tempo agli stilisti vintage e contemporanei. Scopri lo stile e la raffinatezza del nostro esclusivo assortimento.',
    headingTitle: 'Scopri brand di lusso dalla A alla Z',
  },
  de: {
    title: 'Entdecken Sie Luxus-Designermarken bei Vestiaire Collective',
    description:
      'Eine kuratierte Auswahl ikonischer Marken, von zeitlosen Klassikern bis hin zu Vintage- und zeitgenössischen Modedesignern. Entdecken Sie den Stil und die Raffinesse unseres exklusiven Sortiments.',
    headingTitle: 'Entdecken Sie Luxus-Designermarken von A bis Z',
  },
  es: {
    title: 'Explora las marcas de diseñadores de lujo en Vestiaire Collective',
    description:
      'Una cuidada selección de marcas icónicas, desde clásicos atemporales hasta diseñadores de moda vintage y contemporáneos. Descubre el estilo y la sofisticación de nuestra selección exclusiva de moda.',
    headingTitle: 'Descubre las marcas de lujo de la A-Z',
  },
  us: {
    title: 'Explore Luxury Designer Brands at Vestiaire Collective',
    description:
      'A curated selection of iconic brands, from timeless classics to vintage and contemporary fashion designers. Uncover the style and sophistication in our exclusive assortment.',
    headingTitle: 'Discover Luxury Designer Brands A-Z',
  },
  ko: {
    title: 'Explore Luxury Designer Brands at Vestiaire Collective',
    description:
      'A curated selection of iconic brands, from timeless classics to vintage and contemporary fashion designers. Uncover the style and sophistication in our exclusive assortment.',
    headingTitle: 'Discover Luxury Designer Brands A-Z',
  },
  hk: {
    title: 'Explore Luxury Designer Brands at Vestiaire Collective',
    description:
      'A curated selection of iconic brands, from timeless classics to vintage and contemporary fashion designers. Uncover the style and sophistication in our exclusive assortment.',
    headingTitle: 'Discover Luxury Designer Brands A-Z',
  },
  sv: {
    title: 'Explore Luxury Designer Brands at Vestiaire Collective',
    description:
      'A curated selection of iconic brands, from timeless classics to vintage and contemporary fashion designers. Uncover the style and sophistication in our exclusive assortment.',
    headingTitle: 'Discover Luxury Designer Brands A-Z',
  },
  nl: {
    title: 'Explore Luxury Designer Brands at Vestiaire Collective',
    description:
      'A curated selection of iconic brands, from timeless classics to vintage and contemporary fashion designers. Uncover the style and sophistication in our exclusive assortment.',
    headingTitle: 'Discover Luxury Designer Brands A-Z',
  },
};
