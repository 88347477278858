import React, { useCallback } from 'react';
import clsx from 'clsx';
import useOnReveal from '@hooks/use-on-reveal';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import styles from './banner-content.module.scss';

type BannerContentProps = {
  isActiveBanner: boolean;
  link?: { target?: string; href: string; title?: string };
  textColor?: string;
  backgroundColor?: string;
  sectionIdx?: string;
  idx: number;
  _metadata?: { uid?: string };
  contentTypeUID?: string;
  category?: string;
  content?: string;
};

const BannerContent: React.FC<BannerContentProps> = (props): React.JSX.Element => {
  const {
    link,
    isActiveBanner,
    textColor,
    backgroundColor: contentBGColor,
    sectionIdx,
    idx,
    category,
    contentTypeUID,
    _metadata,
    content,
  } = props;

  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  const innerStyle = textColor ? { color: textColor } : {};
  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        category: 'cms_content_view',
        action: contentTypeUID,
        label: `${String(idx + 1)}_${sectionIdx}`,
        property: _metadata?.uid,
      });
    }, [contentTypeUID, category, idx, _metadata?.uid]),
    0.5,
    false,
  );

  const { target = '_self', href, title = '' } = link || {};

  const trackBannerClick = (e) => {
    sendAnalyticEvent('cms_content_click', {
      category: 'cms_content_click',
      action: contentTypeUID,
      label: `${String(idx + 1)}_${sectionIdx}`,
      property: _metadata?.uid,
    });
    e.stopPropagation();
  };

  if (!content) {
    return null;
  }

  return (
    <div
      className={clsx(styles.banner__item, isActiveBanner ? styles['banner__item--on'] : styles['banner__item--off'])}
      style={contentBGColor ? { backgroundColor: contentBGColor } : {}}
      ref={containerRef}
    >
      <a
        className={styles.banner__content}
        {...{ href, title, target }}
        style={innerStyle}
        role="button"
        tabIndex={0}
        onClick={trackBannerClick}
        onKeyPress={trackBannerClick}
      >
        {content}
      </a>
    </div>
  );
};

export default BannerContent;
