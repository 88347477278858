import React, { useState, useMemo, FCWithChildren } from 'react';
import clsx from 'clsx';
import style from './accordion.module.scss';

type ButtonType = 'info' | 'success' | 'warning' | 'error';

type ColorMapType = {
  [p in ButtonType]: string;
};

const ColorMap: ColorMapType = {
  info: 'transparent',
  success: '#edf7ed',
  warning: '#fff7eb',
  error: '#fdeded',
};

type AccordionProps = {
  disabled?: boolean;
  defaultExpanded?: boolean;
  header: React.JSX.Element | string;
  icon: React.JSX.Element;
  type?: ButtonType;
  backgroundColor?: string;
  customClass?: string;
} & React.HTMLProps<HTMLDivElement>;

const Accordion: FCWithChildren<AccordionProps> = (props) => {
  const {
    disabled = false,
    defaultExpanded = false,
    header,
    icon,
    type = 'info',
    backgroundColor,
    customClass,
    children,
  } = props;

  const [expanded, setExpended] = useState<boolean>(defaultExpanded);
  const bkgColor = useMemo(() => {
    if (backgroundColor !== undefined) {
      return backgroundColor;
    }
    return ColorMap[type];
  }, [type, backgroundColor]);

  const handleClick = () => {
    setExpended(!expanded);
  };

  return (
    <div
      className={clsx(style['accordion-container'], customClass)}
      style={{ backgroundColor: bkgColor }}
    >
      <button
        type="button"
        disabled={disabled}
        onClick={handleClick}
        className={clsx(style['accordion-inner'], style[`accordion-inner__${expanded ? 'open' : 'close'}`])}
      >
        <div className={style['accordion-icon-container']}>
          <div className={style['accordion-icon']}>{icon}</div>
        </div>
        <div className={style['accordion-title']}>{header}</div>
      </button>
      <div className={clsx(style['accordion-content'], style[`accordion-content__${expanded ? 'open' : 'close'}`])}>
        {children}
      </div>
    </div>
  );
};

export default Accordion;
