import { axios, getAxiosConfig, ServerAxiosRequestData } from '@config/axios';
import Environment from '@config/index';
import { Product } from '@interfaces/models/product';
import { Negotiation } from '@interfaces/models/negotiation';
import { ApiResponse } from '@interfaces/api';
import { ProductConditionLevel } from '@interfaces/models/productConditionLevel';
import { Translation } from '@interfaces/models/translation';
import { Shipping } from '@interfaces/models/shipping';
import { Comment } from '@interfaces/models/comment';
import { NegotiationRequest } from '@interfaces/models/negotiationRequest';
import errorGuard from '@helpers/decorators/error-guard-decorator';
import { CreateProductCommentPayload } from '@interfaces/api/payloads/create-product-comment-payload';
import { SimilarProduct } from '@interfaces/models/SimilarProduct';

type CreateNegotiationPayload = {
  offerInWholeNumber: NegotiationRequest['offerInWholeNumber'];
};

@errorGuard('listSimilarProducts')
export default class ProductService {
  static apiBaseUrl = Environment.apiBaseUrl;

  static async getProduct(productId: Product['id'], serverAxiosRequestData?: ServerAxiosRequestData): Promise<Product> {
    const axiosConfig = getAxiosConfig(serverAxiosRequestData);

    const {
      data: { data },
    } = await axios.get<ApiResponse<Product>>(`${this.apiBaseUrl}/products/${productId}`, axiosConfig);
    return data;
  }

  static async getProductWithBuyerId(
    productId: Product['id'],
    buyerId: string,
    serverAxiosRequestData: ServerAxiosRequestData,
  ): Promise<Product> {
    const axiosConfig = getAxiosConfig(serverAxiosRequestData);

    const {
      data: { data },
    } = await axios.get<ApiResponse<Product>>(
      `${this.apiBaseUrl}/products/${productId}?buyerId=${buyerId}`,
      axiosConfig,
    );
    return data;
  }

  static async getProductConditionDetails(productId: Product['id']): Promise<ProductConditionLevel[]> {
    const axiosConfig = getAxiosConfig();

    const {
      data: { data },
    } = await axios.get<ApiResponse<ProductConditionLevel[]>>(
      `${this.apiBaseUrl}/products/${productId}/condition-details`,
      axiosConfig,
    );
    return data;
  }

  static async translation(commentId: Comment['id'], productId: Product['id']): Promise<Translation> {
    const axiosConfig = getAxiosConfig();

    const {
      data: { data },
    } = await axios.get<ApiResponse<Translation>>(
      `${this.apiBaseUrl}/products/${productId}/comments/${commentId}/translation`,
      axiosConfig,
    );
    return data;
  }

  static async createProductComment(productId: Product['id'], payload: CreateProductCommentPayload): Promise<Comment> {
    const axiosConfig = getAxiosConfig();
    const {
      data: { data },
    } = await axios.post<ApiResponse<Comment>>(
      `${this.apiBaseUrl}/products/${productId}/comments`,
      payload,
      axiosConfig,
    );
    return data;
  }

  static async listSimilarProducts({
    productId,
    limit = 12,
    disableSizeFilter = false,
    serverAxiosRequestData,
  }: {
    productId: Product['id'];
    limit?: number;
    disableSizeFilter?: boolean;
    serverAxiosRequestData?: ServerAxiosRequestData;
  }) {
    const axiosConfig = getAxiosConfig(serverAxiosRequestData);
    const {
      data: { data },
    } = await axios.get<ApiResponse<SimilarProduct[]>>(
      `${this.apiBaseUrl}/products/${productId}/similar-products?limit=${limit}${
        disableSizeFilter ? '&disableSizeFilter=true' : ''
      }`,
      axiosConfig,
    );
    const similarProductsWithCountry = data?.map((product) => ({ ...product, isoCountry: product.country }));
    return similarProductsWithCountry ?? [];
  }

  static async listProductComments(
    productId: Product['id'],
    limit: number,
    offset: number,
  ): Promise<ApiResponse<Comment[]>> {
    const axiosConfig = getAxiosConfig();
    axiosConfig.params = {
      ...axiosConfig.params,
      limit,
      offset,
    };

    const { data } = await axios.get<ApiResponse<Comment[]>>(
      `${this.apiBaseUrl}/products/${productId}/comments`,
      axiosConfig,
    );

    // This response is kinda whack, if no comments are present on a product, meta is an empty object, but we need a count
    if (!data.meta.hasOwnProperty('count')) {
      data.meta.count = 0;
    }

    return data;
  }

  static async getActiveNegotiation(productId: Product['id'], withEvents: boolean): Promise<Negotiation> {
    const axiosConfig = getAxiosConfig();

    if (withEvents) {
      axiosConfig.params.withEvents = withEvents;
    }

    const {
      data: { data },
    } = await axios.get<ApiResponse<Negotiation>>(
      `${this.apiBaseUrl}/products/${productId}/active-negotiation`,
      axiosConfig,
    );
    return data;
  }

  static async deletePriceDrop(productId: Product['id']): Promise<void> {
    const axiosConfig = getAxiosConfig();
    await axios.delete<ApiResponse<void>>(`${this.apiBaseUrl}/products/${productId}/price-drop-alerts`, axiosConfig);
  }

  static async likeProduct(productId: Product['id']): Promise<void> {
    const axiosConfig = getAxiosConfig();
    await axios.post<ApiResponse<void>>(`${this.apiBaseUrl}/products/${productId}/likes`, null, axiosConfig);
  }

  static async unlikeProduct(productId: Product['id']): Promise<void> {
    const axiosConfig = getAxiosConfig();

    await axios.delete<ApiResponse<void>>(`${this.apiBaseUrl}/products/${productId}/likes`, axiosConfig);
  }

  // TODO: This should be in user-services
  static async getProductsFromPriceDropAlertList(): Promise<Product['id'][]> {
    const axiosConfig = getAxiosConfig();
    const {
      data: { data },
    } = await axios.get<ApiResponse<Product['id'][]>>(`${this.apiBaseUrl}/users/me/price-drop-alerts`, axiosConfig);
    return data;
  }

  static async getProductShippingInformation(
    productId: Product['id'],
    serverAxiosRequestData?: ServerAxiosRequestData,
  ): Promise<Shipping> {
    const axiosConfig = getAxiosConfig(serverAxiosRequestData);

    const {
      data: { data },
    } = await axios.get<ApiResponse<Shipping>>(`${this.apiBaseUrl}/products/${productId}/shippings`, axiosConfig);
    return data;
  }

  static async createNegotiation(productId: Product['id'], payload: CreateNegotiationPayload): Promise<Negotiation> {
    const axiosConfig = getAxiosConfig();
    const {
      data: { data },
    } = await axios.post<ApiResponse<Negotiation>>(
      `${this.apiBaseUrl}/products/${productId}/negotiations`,
      payload,
      axiosConfig,
    );
    return data;
  }
}
