import React, { FCWithChildren, useEffect, useRef, useState } from 'react';
import { usePreferences } from '@context/preferences.context';
import { useAnalytics } from '@context/analytics.context';
import { useContextSelector } from 'use-context-selector';
import { UserContext } from '@context/user.context';
import { DataLayerInterface } from '@interfaces/models/dataLayerInterface';
import getCatalogQueryIndex from '@helpers/utils/catalog-query-index';
import { productToListProduct } from '@helpers/utils/catalog/catalog-parsing-utils';
import { CatalogContext, CatalogContextProps } from '@context/catalog.context';
import { CampaignContext } from '@context/campaign.context';
import { ElasticSearchFilterKeys } from '@interfaces/models/elasticSearch';
import { useRouter } from 'next/router';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';

export type CatalogDatalayerType = Pick<
  DataLayerInterface,
  | 'catalog_version'
  | 'index_name'
  | 'nbHits'
  | 'perso_available'
  | 'perso_created'
  | 'perso_enabled'
  | 'perso_results_nb'
  | 'perso_results_returned'
  | 'list_products'
  | 'search_query_id'
  | 'filters_details'
  | 'catalog_brand'
  | 'catalog_universe'
  | 'catalog_category'
  | 'catalog_subcategory'
  | 'nb_results'
  | 'cms_block_id'
  | 'filters_applied'
  | 'are_filters_active'
>;

export const pickSearchContextAttributes = (
  attributes: (keyof DataLayerInterface)[],
): Partial<CatalogDatalayerType> => {
  if (typeof window === 'undefined') {
    return {};
  }
  const dataLayer = window.tc_vars;
  if (!dataLayer) {
    return {};
  }
  return attributes.reduce((acc, attribute) => {
    acc[attribute] = dataLayer[attribute];
    return acc;
  }, {});
};

export const RESET_CATALOG_DATALAYER: Partial<DataLayerInterface> = {
  keyword: '',
  keyword_user_input: '',
  keyword_autocorrected: '',
  campaign_id: '',
  onsite_campaign_id: '',
  filtered_campaign_id: '',
  pageUrl: '',
};

const getSuggestionProperties = ({
  keywordSuggestion,
  searchQuery,
  originalQuery,
}: {
  keywordSuggestion: CatalogContextProps['keywordSuggestion'];
  searchQuery: string;
  originalQuery?: string;
}) => {
  // autocorrection case
  if (keywordSuggestion.isAutoCorrected) {
    return {
      keyword: keywordSuggestion.suggestedSearchQuery ?? '',
      keyword_autocorrected: 'true',
      keyword_user_input: searchQuery,
    };
  }
  // did-you-mean but has not clicked on suggestion yet
  if (keywordSuggestion.hasSuggestion) {
    return {
      keyword_autocorrected: 'false',
    };
  }
  // did-you-mean after clicking on suggestion
  if (!!originalQuery) {
    return {
      keyword_autocorrected: 'false',
      keyword_user_input: originalQuery,
    };
  }
  return {};
};

const CatalogDatalayer: FCWithChildren = ({ children }) => {
  const { query } = useRouter();
  const { country } = usePreferences();
  const { scriptsEventData } = useAnalytics();
  const { updateDataLayer, sendAnalyticEvent } = useAnalyticEvents('catalog');
  const userSizes = useContextSelector(UserContext, (v) => v.userSizes);
  const queryResult = useContextSelector(CatalogContext, (v) => v.queryResult);
  const areMySizesEnabled = useContextSelector(CatalogContext, (v) => v.areMySizesEnabled);
  const areMySizesApplicable = useContextSelector(CatalogContext, (v) => v.areMySizesApplicable);
  const searchQuery = useContextSelector(CatalogContext, (v) => v.searchQuery);
  const sortBy = useContextSelector(CatalogContext, (v) => v.sortBy);
  const products = useContextSelector(CatalogContext, (v) => v.products);
  const filters = useContextSelector(CatalogContext, (v) => v.filters);
  const searchQueryId = useContextSelector(CatalogContext, (v) => v.searchQueryId);
  const keywordSuggestion = useContextSelector(CatalogContext, (v) => v.keywordSuggestion);
  const campaign = useContextSelector(CampaignContext, (v) => v.campaign);
  const pageViewSentRef = useRef(false);
  const [isDatalayerUpdated, setIsDatalayerUpdated] = useState(false);

  // Manually sending page view event on PLP - since we need to have product request completed before sending pageview event
  // this way we can guarantee that all search_context properties are correct
  useEffect(() => {
    const doSendPageViewEvent =
      !pageViewSentRef.current &&
      isDatalayerUpdated &&
      scriptsEventData?.isTcEvents7Loaded &&
      !!window.trackSnowplowPageView;
    if (doSendPageViewEvent) {
      pageViewSentRef.current = true;
      window.trackSnowplowPageView();
    }
  }, [isDatalayerUpdated, scriptsEventData?.isTcEvents7Loaded]);

  useEffect(() => {
    if (!queryResult) {
      return;
    }

    const areFiltersActive = String(
      !!Object.keys(filters || {}).filter(
        (x: ElasticSearchFilterKeys) => x !== 'catalogLinksWithoutLanguage' && x !== 'campaign.id',
      ).length,
    );
    const hasMysizes = !!(userSizes?.universe?.ids && userSizes.universe.ids.length > 0);
    const queryResultFacets = queryResult.facets?.fields ?? {};

    const state: CatalogDatalayerType = {
      catalog_version: 'standard',
      index_name: getCatalogQueryIndex(country, sortBy),
      nbHits: queryResult.paginationStats.totalHits,
      perso_available: String(areMySizesApplicable),
      perso_created: String(hasMysizes),
      perso_enabled: String(areMySizesEnabled),
      perso_results_nb: areMySizesEnabled ? queryResult.paginationStats.totalHits : 0,
      perso_results_returned: areMySizesEnabled && queryResult.paginationStats.totalHits > 0,
      list_products: productToListProduct(products),
      search_query_id: searchQueryId.hasLoaded ? searchQueryId.id : '',
      catalog_brand: queryResultFacets?.brand?.length === 1 ? queryResultFacets.brand[0].name : '',
      catalog_universe: filters?.['universe.id'] ? filters['universe.id'].join(',') : '',
      catalog_category: filters?.['categoryLvl0.id'] ? filters['categoryLvl0.id'].join(',') : '',
      catalog_subcategory: filters?.['categoryLvl1.id'] ? filters['categoryLvl1.id'].join(',') : '',
      cms_block_id: campaign?.uid ?? '',
      filters_applied: areFiltersActive,
      are_filters_active: areFiltersActive,
      nb_results: String(queryResult.paginationStats.totalHits),
      filters_details: filters ?? {},
      ...getSuggestionProperties({ keywordSuggestion, searchQuery, originalQuery: query?.originalQuery as string }),
    };

    updateDataLayer(state);
    setIsDatalayerUpdated(true);
  }, [queryResult, products, searchQuery, sortBy, userSizes, areMySizesEnabled, filters, searchQueryId]);

  const didYouMeanViewEventSentRef = useRef(false);
  // Event triggered when "did you mean" banner is shown
  useEffect(() => {
    if (!didYouMeanViewEventSentRef.current && keywordSuggestion.hasSuggestion && !keywordSuggestion.isAutoCorrected) {
      didYouMeanViewEventSentRef.current = true;
      sendAnalyticEvent('did_you_mean_view', {
        label: keywordSuggestion.suggestedSearchQuery,
      });
    }
  }, [keywordSuggestion]);

  return <>{children}</>;
};

export default CatalogDatalayer;
