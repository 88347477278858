import { ParsedUrlQuery } from 'querystring';
import { CatalogPageMetadataResponse } from '@interfaces/api/responses/catalog-page-metadata-response';

export const isPersonalizedNewInsPage = (url: string): boolean => url.includes('/new-items-for-you/');

export const isSearchPage = (url: string): boolean => {
  return /\bsearch\b/.test(url);
};

export const isDirectShippingPage = (url: string): boolean => {
  const directShippingPageUrls = [
    '/direct-shipping/',
    '/envoi-direct/',
    '/spedizione-diretta/',
    '/envio-directo/',
    '/direktversand/',
    '/direktleverans/',
    '/directe-verzending/',
  ];
  return directShippingPageUrls.some((path) => url?.includes(path));
};

export const isExpressDeliveryPage = (url: string): boolean => {
  const expressDeliveryUrls = [
    '/express-delivery/',
    '/livraison-express/',
    '/consegna-express/',
    '/entrega-express/',
    '/expressversand/',
    '/express-levering/',
    '/expressfrakt/',
  ];
  return expressDeliveryUrls.some((path) => url?.includes(path));
};

export const isCatalogPage = (url: string): boolean => {
  return isSearchPage(url) || isDirectShippingPage(url) || isExpressDeliveryPage(url);
};

export const isHomePage = (url: string): boolean => {
  return url === '/';
};

export const isEditorialPage = (url: string): boolean => {
  const pathSegments = url.split('/') || [];

  return pathSegments[0] === 'journal';
};

export const isPricingCalculatorPage = (url: string): boolean => {
  return /\bsell\/pricing-calculator\b/.test(url);
};

export const isLandingPage = (url: string): boolean => {
  const firstPathName = 'cms';
  const secondLevelPathNames = [
    'web-ds-ed',
    'talking-fashion',
    'app-ds-ed',
    'channels',
    'top-seller',
    'homemade-by-vc',
    'my-community',
    'raas',
    'daily-deals',
    'description',
  ];
  const pathSegments = (url.split('/') || []).filter((item) => item);

  if (pathSegments.length < 2) {
    return false;
  }

  return !!(pathSegments[0] === firstPathName && secondLevelPathNames.includes(pathSegments[1]));
};

export const isWebview = (query: ParsedUrlQuery): boolean => {
  if (!query) {
    return false;
  }

  return !!(query.webView || query.webview || query.native);
};

export const getLandingPageType = (url: string): string => {
  if (!url) {
    return null;
  }

  const pathAsArr = url.split('/')?.filter((path) => !!path);

  if (pathAsArr[0] === 'cms') {
    return pathAsArr[1];
  }

  return null;
};

export const getSearchKeyword = (query: string, path: string, metadata: CatalogPageMetadataResponse): string => {
  if (query) {
    return query;
  }
  if (!isSearchPage(path) && metadata?.template === 'brand-page') {
    return path.split('?')[0].replace(/\//g, '');
  }
  return 'no keyword';
};

export const isNewInPage = (url: string): boolean => {
  const newInPathNames = ['new-items', 'nouveautes', 'novita', 'novedades', 'neuheiten', 'nieuwe-items', 'nyheter'];
  return newInPathNames.some((p) => url.includes(p));
};

export const extractUrlfromPrerenderDataReq = (contextQuery: ParsedUrlQuery, slug: string) => {
  const url = (contextQuery[slug] as []).join('/');
  const queryStr = Object.keys(contextQuery)
    .filter((k) => k !== slug)
    .map((e) => `${e}=${contextQuery[e]}`)
    .join('&');
  return `/${url}/${queryStr !== '' ? '?' : ''}${queryStr}`;
};

export const updateParamInUrl = (name: string, value: string) => {
  const { search, pathname, origin, hash } = window.location;
  const queryParams = new URLSearchParams(search);
  if (queryParams.get(name) === null) {
    queryParams.append(name, value);
  } else {
    queryParams.set(name, value);
  }
  const queryString = queryParams.toString();
  const newUrl = `${origin}${pathname}${queryString ? `?${queryParams}` : ''}${hash}`;
  window.history.replaceState(null, undefined, newUrl);
};
