import React, { useCallback, useMemo, useState } from 'react';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import useOnReveal from '@hooks/use-on-reveal';
import { useTranslation } from 'next-i18next';
import ProductCardSkeleton from '@components/organisms/catalog/components/skeleton-components/product-card-skeleton/product-card-skeleton';
import DialogWrapper from '@components/catalog/my-sizes/my-sizes/dialog-wrapper/dialog-wrapper';
import useUser from '@hooks/user/use-user';
import { UserContext } from '@context/user.context';
import { CatalogProduct } from '@interfaces/models/catalogProduct';
import { Product } from '@interfaces/models/product';
import { useContextSelector } from 'use-context-selector';
import clsx from 'clsx';
import ProductBlock from '../product-block/product-block';
import { ProductQuery, ProductsContext, SeasProductsProvider, LocalProductsProvider } from './providers';
import styles from './product-general-component.module.scss';

interface ProductsProviderProps extends ProductQuery {
  datasource: 'seas' | 'local';
}

interface ProductsRowProps extends ProductQuery {
  products: Product[] | CatalogProduct[];
  isLoading?: boolean;
  isLocal?: boolean;
  uuid?: number;
  sectionIdx: string;
  contentTypeUid?: string;
}

/**
 * Renders general product block.
 */
const ProductsRow: React.FC<ProductsRowProps> = ({
  products,
  isLoading,
  isPersonalizable,
  displayTitle: title,
  productType,
  ctaText,
  url,
  uuid: sectionId,
  sectionIdx,
  minProductCount,
}): React.JSX.Element => {
  const cta = { href: url, title: ctaText };
  const { sendAnalyticEvent, updateDataLayer } = useAnalyticEvents('cms_component');
  const { t } = useTranslation();
  const { isAuthenticated } = useUser();
  const isPersonalized = isAuthenticated && isPersonalizable;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { containerRef } = useOnReveal(
    useCallback(() => {
      if (products.length > 0) {
        sendAnalyticEvent('cms_content_view', {
          category: 'cms_content_view',
          action: 'product',
          property: `${sectionId}_${productType}`,
          label: sectionIdx,
        });
      }
    }, [productType, sectionId, sectionIdx, sendAnalyticEvent]),
    0.25,
    false,
  );

  const updataDataLayerOnClick = () => {
    updateDataLayer({
      source_category: 'cms_block',
      source_subcategory: productType,
    });
    sendAnalyticEvent('cms_content_click', {
      category: 'cms_content_click',
      action: 'product',
      property: `${sectionId}_${productType}`,
      label: sectionIdx,
    });
  };

  // Check if cta is an empty object
  const areCtaValuesNonEmpty = Object.values(cta).some((value) => value !== '');
  const updatedCta = areCtaValuesNonEmpty ? { ...cta, sendClickAnalytics: updataDataLayerOnClick } : null;

  const userSizes = useContextSelector(UserContext, (v) => v.userSizes);

  const doesUserHaveSizes = useMemo<boolean>(() => {
    return userSizes?.universe?.ids.length > 0;
  }, [userSizes]);

  const mySizes = useMemo(() => {
    const link = (
      <span
        aria-hidden
        className={clsx(styles.products_container__resultContainer__subtitle__button, 'vc-text-m')}
        onClick={setDialogOpen.bind(null, true)}
        onKeyDown={setDialogOpen.bind(null, true)}
        key="mysizes-button"
      >
        {doesUserHaveSizes ? t('HOMEPAGE.MYSIZES_BUTTON') : t('HOMEPAGE.MYSIZES_BUTTON_NOPERSO')}
      </span>
    );
    const text = (
      <span
        className="vc-text-m"
        key="mysizes-title"
      >
        {doesUserHaveSizes ? t('HOMEPAGE.MYSIZES_TITLE') : t('HOMEPAGE.MYSIZES_TITLE_NOPERSO')}
      </span>
    );

    return (
      <div className={clsx(styles.products_container__resultContainer__subtitle, 'innerContainer')}>
        {doesUserHaveSizes ? [text, link] : [link, text]}
      </div>
    );
  }, [doesUserHaveSizes]);

  return (
    <>
      <section
        ref={containerRef}
        className={styles.product_general_component}
        data-cy={`product_block_${productType || 'container'}`}
      >
        <div className={styles.product_row}>
          <div className={styles.product_row__catalog}>
            {isLoading ? (
              <ul className={styles.product_row__catalog__loadingContainer}>
                {Array.from(Array(4).keys()).map((key) => (
                  <li key={key}>
                    <ProductCardSkeleton />
                  </li>
                ))}
              </ul>
            ) : (
              <div className={styles.products_container__resultContainer}>
                <ProductBlock
                  products={products}
                  title={title}
                  productType={productType}
                  showAuthGuard={true}
                  cta={updatedCta}
                  subTitle={isPersonalized && mySizes}
                  productCardProps={{
                    moduleType: productType,
                    productType: productType,
                    pageType: 'cms_page',
                  }}
                  minProductCount={minProductCount}
                />
              </div>
            )}
          </div>
        </div>
        {isPersonalized && (
          <DialogWrapper
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            canActivateSizes={true}
          />
        )}
      </section>
    </>
  );
};

const shouldRenderFallback = (props: ProductQuery, isError: boolean, productsCount: number) => {
  if (!props.fallback?.length || !props.fallbackComponents?.[props.fallback]) {
    return false;
  }

  return isError || productsCount < (props.minProductCount ?? 0);
};

/**
 * Renders products list from ProductsContext.
 */
const ProductsContextRenderer: React.FC<ProductQuery> = (props) => {
  const { products, isLoading, isError, isLocal } = React.useContext(ProductsContext);
  if (!isLoading && shouldRenderFallback(props, isError, products?.length ?? 0)) {
    const FallbackComponent = props.fallbackComponents[props.fallback];
    return <FallbackComponent />;
  }

  return (
    <ProductsRow
      products={products}
      isLoading={isLoading}
      isLocal={isLocal}
      {...props}
    />
  );
};

/**
 * Fetches and renders products from specified source type.
 */
const ProductsProviderListComponent: React.FC<ProductsProviderProps> = ({ datasource = 'seas', ...props }) => {
  switch (datasource) {
    case 'seas':
      return (
        <SeasProductsProvider query={props!}>
          <ProductsContextRenderer {...props} />
        </SeasProductsProvider>
      );
    case 'local':
      return (
        <LocalProductsProvider query={props!}>
          <ProductsContextRenderer {...props} />
        </LocalProductsProvider>
      );
    default:
      return <b>Invalid source type: {datasource}</b>;
  }
};

/**
 *
 * <ProductsProviderListComponent
 *  sourceType="seas"
 *  seas={{ cta: {}, isPersonalized: true, productType: 'product', productFeedIndex: 'ng-products-slave-recent' }}
 *  rowProps={{ ctaText: 'View All', url: '/products', sectionIdx: 1 }}
 * />
 *
 * <ProductsProviderListComponent
 *  sourceType="local"
 *  rowProps={{ ctaText: 'View All', url: '/products', sectionIdx: 1 }}
 * />
 */
export default ProductsProviderListComponent;
