import { useQuery, useQueryClient } from '@tanstack/react-query';
import { NotificationQueryKeys } from '@enums/react-query-keys';
import { NotificationFeedCounterResponse } from '@interfaces/api/responses/notification-feed-counter-response';
import { getNotificationCount } from '@api/notifications';
import useUser from '@hooks/user/use-user';
import { useBucket } from '@context/bucket.context';

const DEFAULT_NOTIFICATION_FEED_COUNTERS = {
  activity: 0,
  alerts: 0,
  all: 0,
  community: 0,
  messages: 0,
};

const useNotificationCountQuery = () => {
  const queryClient = useQueryClient();
  const { user, isAuthenticated } = useUser();
  const { isFeatureEnabled } = useBucket();
  const notificationsV2Revamp = isFeatureEnabled((f) => !!f.notificationCenterPhaseTwoScreenRevamp, true);

  const { data: notificationFeedCounters } = useQuery<NotificationFeedCounterResponse['unseen']>({
    queryKey: [NotificationQueryKeys.NOTIFICATION_FEED_COUNTER, user?.id],
    queryFn: () => getNotificationCount(),
    refetchInterval: 90000,
    refetchIntervalInBackground: !notificationsV2Revamp && notificationsV2Revamp !== undefined,
    enabled: isAuthenticated && !notificationsV2Revamp && notificationsV2Revamp !== undefined,
    initialData: DEFAULT_NOTIFICATION_FEED_COUNTERS,
  });

  // TODO: This count should be sum of (all + messages) from unseen object, once the notification migration has done, has to clean up this.
  const notificationCount: number = notificationFeedCounters.all;

  // Whenever notification popin opens, we need reset the feed counters back to 0
  const resetNotificationFeedCounters = () => {
    queryClient.setQueryData(
      [NotificationQueryKeys.NOTIFICATION_FEED_COUNTER, user?.id],
      () => DEFAULT_NOTIFICATION_FEED_COUNTERS,
    );
  };

  return {
    notificationFeedCounters,
    notificationCount,
    resetNotificationFeedCounters,
  };
};

export default useNotificationCountQuery;
