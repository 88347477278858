import { ContentsPageMetadata } from '@interfaces/models/contentsPageMetadata';
import NextHead from 'next/head';
import React, { useState } from 'react';
import { ContentsLocalizedPath } from '@interfaces/models/contentsLocalizedPath';
import useInitialRequestUrlQuery from '@hooks/use-initial-request-url-query';

type HeadProps = {
  children?: React.ReactNode;
  metadata?: ContentsPageMetadata;
  alternateVersions?: ContentsLocalizedPath[];
  canonicalPath?: string;
};

export const DefaultAlternateVersions = [
  { language: 'x-default', path: '/' },
  { language: 'en', path: '/' },
  { language: 'us', path: '/' },
  { language: 'fr', path: '/' },
  { language: 'de', path: '/' },
  { language: 'es', path: '/' },
  { language: 'it', path: '/' },
  { language: 'ko', path: '/' },
  { language: 'en-kr', path: '/' },
  { language: 'zh-hk', path: '/' },
];

export const generateAlternateLink = (alternateVersion: ContentsLocalizedPath): string => {
  const { language, path } = alternateVersion;

  const subdomainMap = {
    'x-default': 'www',
    en: 'www',
    'en-kr': 'www',
    'ko-kr': 'kr',
    ko: 'kr',
    'zh-hk': 'hk',
  };

  const subdomain = subdomainMap[language] ?? language;
  const link = `https://${subdomain}.vestiairecollective.com${path}`;
  // PDP links can not end with '/'
  const isPDPLink: boolean = link.endsWith('.shtml');
  return link.endsWith('/') || isPDPLink ? link : `${link}/`;
};

const generateAlternateHrefLang = (language: ContentsLocalizedPath['language']): string => {
  const languageMap = {
    en: 'en-gb',
    us: 'en-us',
    ko: 'ko-kr',
    hk: 'zh-hk',
  };

  return languageMap[language] ?? language;
};

const Head: React.FC<HeadProps> = (props): React.JSX.Element => {
  const { metadata = {} as ContentsPageMetadata, alternateVersions = [], children, canonicalPath } = props;

  const { initialRequestUrl } = useInitialRequestUrlQuery();

  const { title, description, robots, keywords } = metadata;

  const [canonicalUrl] = useState<string>(() => {
    // Server
    if (typeof window === 'undefined') {
      const [initialRequestURLWithoutParameters] = initialRequestUrl.href.split('?');

      // For some reason, '.fenx' subdomain gets appended, and we need to remove it.
      const initialRequestURLWithCorrectSubdomain = initialRequestURLWithoutParameters.replace('.fenx', '');

      // Canonical url cannot contain any pagination section, so we remove '/p-{digits}'
      const url = new URL(initialRequestURLWithCorrectSubdomain.replace(/\/p-[0-9]+/, ''));
      return `${url.protocol}//${url.host}${canonicalPath || url.pathname}`;
    }
    // Client
    return `${location.protocol}//${location.host}${canonicalPath || location.pathname}`;
  });

  // Because some versions might be missing, we'll be manually setting those which are not present
  const allAlternateVersions = () => {
    if (alternateVersions.length === 0) {
      return [];
    }
    // English path is used for korean links
    const enPath = alternateVersions.find((alternate) => alternate.language === 'en')?.path;

    // Sometimes we might not get all required alternate versions
    // Korean version can either be 'ko-kr' or 'ko'
    const hasKoKRLanguage: boolean = alternateVersions.some(
      (alternate) => alternate.language === 'ko-kr' || alternate.language === 'ko',
    );
    const hasEnKRLanguage: boolean = alternateVersions.some((alternate) => alternate.language === 'en-kr');

    return [
      ...alternateVersions,
      ...(!hasKoKRLanguage ? [{ language: 'ko-kr', path: enPath }] : []),
      ...(!hasEnKRLanguage ? [{ language: 'en-kr', path: enPath }] : []),
    ];
  };

  return (
    <NextHead>
      <base href="/" />
      <title>{title}</title>
      <meta
        property="og:title"
        content={title}
        key="og:title"
      />
      <meta
        key="description"
        name="description"
        content={description}
      />
      <meta
        key="og:description"
        property="og:description"
        content={description}
      />
      <meta
        key="viewport"
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <meta
        name="mobile-web-app-capable"
        content="yes"
      />
      <meta
        name="apple-mobile-web-app-capable"
        content="yes"
      />
      {robots && (
        <meta
          key="robots"
          name="robots"
          content={robots}
        />
      )}
      {keywords && (
        <meta
          key="keywords"
          name="keywords"
          content={keywords}
        />
      )}
      <link
        key="canonical"
        rel="canonical"
        href={canonicalUrl}
      />
      {allAlternateVersions().map((alternateVersion: ContentsLocalizedPath) => (
        <link
          key={alternateVersion.language}
          rel="alternate"
          hrefLang={generateAlternateHrefLang(alternateVersion.language)}
          href={alternateVersion.path}
        />
      ))}
      {children}
    </NextHead>
  );
};

export default Head;
